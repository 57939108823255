import * as React from "react";
import "./style.css";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = (data) => {
	return (
		<div className="Main">
			<header className="Main-header">
				<a href="https://twitter.com/Thorlon">
					<StaticImage
						src="../images/avatar.jpg"
						className="Main-logo"
						alt="logo"
						loading="eager"
						width={290}
						height={290}
					/>
				</a>
				<div className="padding">
					<a href="https://twitter.com/Thorlon">
						<StaticImage
							src="../images/twitter-logo.png"
							className="Main-logo"
							alt="twitter"
							loading="eager"
							width={70}
							height={70}
						/>
					</a>
				</div>
			</header>
		</div>
	);
};

export default IndexPage;
